<template>
  <div class="page-wrap gov-model">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">政务大模型客服解决方案</div>
          <div class="desc">政务版智能客服解决方案运用“人工智能+大数据+知识图谱”技术，打造智能和人工协同的全渠道客户服务体系，提供精准识别、智能回复等能力，助力政务服务智能化、高效化。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 需求及解决思路 -->
    <div class="section bg-white solution">
      <div class="title">需求及解决思路</div>
      <div class="content">
        <div class="bg-solution"></div>
      </div>
    </div>

    <!-- 总体架构 -->
    <div class="section arc">
      <div class="title">总体架构</div>
      <div class="content">
        <div class="bg-arc"></div>
      </div>
    </div>

    <!-- 服务流程 -->
    <div class="section bg-white flow">
      <div class="title">服务流程</div>
      <div class="content">
        <div class="bg-flow"></div>
      </div>
    </div>

    <!-- 核心优势 -->
    <div class="section adv">
      <div class="title">核心优势</div>
      <div class="content">
        <div class="adv-list">
          <div class="item" v-for="item in advList" :key="item.name">
            <div class="icon">{{item.name}}</div>
            <div class="info">
              <div class="title">{{item.title}}</div>
              <div class="desc">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 典型案例：赣服通“小赣事” -->
<!--    <div class="section bg-white case">
      <div class="title">典型案例：赣服通“小赣事”</div>
      <div class="content">
        <div class="bg-case"></div>
      </div>
    </div>-->

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advList: [
        {
          name: '精准',
          title: '深耕算法',
          desc: '服务多行业客户，沉淀多行业多场景推荐算法'
        },
        {
          name: '开放',
          title: 'AI生态',
          desc: '智能语音、多模态 大模型、数字人等能力集成'
        },
        {
          name: '自主',
          title: '自研可控',
          desc: '自研语言大模型架构，高效训练和推理引擎'
        },
        {
          name: '安全',
          title: '深度防护',
          desc: '安全前置护栏解决方案 20+种风险全覆盖'
        }
      ]
    }
  },
  methods: {
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
